import React from 'react'
import { Heading, Text, TextContainer } from '../components/shared/Typography'

const NotFoundPage = () => (
  <TextContainer>
    <Heading>Whoops - That Page Doesn’t Exist (404)</Heading>
  </TextContainer>
);

export default NotFoundPage;
